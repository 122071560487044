import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/link.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/components/widget/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductItem"] */ "/app/src/views/product-item/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/stores/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/banner-and-recommendation-content/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/home-hero-slider-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/index-banner-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/multiple-banner-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/single-banner-recommendation-content.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/account/recommendation.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/blog-page-content/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/career-widgets/campus-and-internship/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/career-widgets/open-positions/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/career-widgets/send-your-cv/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/career-widgets/working-with-us/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/carousel-with-content/carousel-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CombinationRecommendationProducts"] */ "/app/src/widgets/combination-recommendation/recommendation.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/contact-informations/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/contact-us-page-two-columns-without-images/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/contact-us.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/customer-service-blocks/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/description-with-slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/designers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/double-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/event-page-blog-posts/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/faq/faq-contact-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/faq/tabs-and-content.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/filterable-item-search/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/footer-actions.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/footer-clup-cart.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/form-field-with-right-photo/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/four-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/four-block-with-content/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/gift-four-product-with-banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/gift-list-creation-section/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/gifts-for-categories/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/global-locations-overview/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GridVideo"] */ "/app/src/widgets/grid-with-video/video.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/home-inspiration-masonry/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/home-inspiration/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/home-recommendation-by-categories.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/home-single-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/image-banner-second/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/image-banner-with-text-and-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/image-banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/inspiration-banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/inspiration-categories/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/news-search-item/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecommendationWithBannerProducts"] */ "/app/src/widgets/recommendation-with-banner/recommendation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShoppableSliderItems"] */ "/app/src/widgets/shoppable-slider/slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/single-shoppable-item/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/slideshow/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/special-projects/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/tabbed-category-area/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/tabs-with-content/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/title-with-slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/triple-designers/designers.tsx");
